import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {fetchOsmPoisRoutesGrouped} from "../actions/fetch_osm_pois_routes_grouped";
import {IPoiStore} from "../reducers/poi_reducer";

export const useOsmPoisRoutesGrouped = (coordinates: {longitude: number; latitude: number; radius: number}): IPoiStore["poisRoutesGrouped"] | null => {
    const poisRoutes = useSelector((state: IRPStore) => state.maps.poi.poisRoutesGrouped);
    const poisRoutesRequest = useSelector((state: IRPStore) => state.maps.poi.poisRoutesGroupedRequest);
    const dispatch = useDispatch();

    useEffect(() => {
        if (poisRoutesRequest || !coordinates?.latitude || !coordinates?.longitude) {
            return;
        }

        dispatch(fetchOsmPoisRoutesGrouped(coordinates));
    }, [coordinates, poisRoutesRequest]);

    return poisRoutes;
};
